import { jamesVanArkel } from '@data/en/employees/items/james-van-arkel';
import { CONTENT_COMPONENT_TYPES, DEVTOOLS } from '@lib/constants';

export const vanWindNaarWatt = {
    path: '/work/van-wind-naar-watt',
    seo: {
        title: 'From Wind to Watt',
        description:
            'To grow digital revenues and extend its online presence, Blast galaxy wanted a website with the spirit of an 80s arcade.',
        image_alt: 'Van Wind Naar Watt project page',
    },
    title: 'How to enhance education with a digital platform?',
    thumbnail: {
        background_color: '#01689B',
        image: {
            path: 'projects/van-wind-naar-watt/van-wind-naar-watt-thumbnail.png',
            alt: 'Van wind naar watt',
        },
    },
    live_demo: 'https://vanwindnaarwatt.nl',
    hero: {
        background_color: '#01689B',
    },
    services: ['Strategy', 'UX design', 'Development'],
    client_details: {
        name: 'Rijksoverheid',
        address: {
            city: 'Den Haag',
        },
        website: 'https://vanwindnaarwatt.nl',
    },
    the_client: {
        title: 'The client',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'Van Wind naar Watt (From Wind to Watt / From Wind to What?) is an initiative by the Dutch government to teach school children about the benefits of wind power. As The Netherlands transitions from a carbon-based to a clean & green economy, ministers want to promote the science behind carbon-zero generation and help children understand the importance of sustainability at an early age.',
            },
            {
                type: CONTENT_COMPONENT_TYPES.IMAGE,
                path: 'projects/van-wind-naar-watt/the-pack-x-bbk.svg',
                alt: 'The Pack working together with BBK',
                extraSpacing: true,
            },
            {
                type: CONTENT_COMPONENT_TYPES.TITLE,
                text: 'Together we create',
            },
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: '&#8220;For the project Van Wind naar Watt we were looking for a partner who could develop a website with information for teachers and a place where children could view videos and images. The Pack came up with new ideas and was open to contribute ideas or to improve processes. Clear agreements were made about when what should be delivered. The Pack was the partner we needed.&#8221;',
            },
            {
                type: CONTENT_COMPONENT_TYPES.REF,
                text: '<b>Karien Reichman</b>, Projectmanager BKK/Doorvriendschap Sterker',
            },
        ],
    },
    the_project: {
        title: 'The objective',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'Working with <a href="https://doorvriendschapsterker.nl/" target="_blank" rel="noreferrer">branding agency BBK</a> and leading Dutch children’s publisher <a href="https://www.zwijsen.nl/" target="_blank" rel="noreferrer">Zwijsen</a>, we were tasked with creating a website that could bring the Wind to Watt curriculum to life. The build needed to support the curriculum’s overall objectives: teach kids about the power of sea-based wind turbines, explain the challenges that arise when moving from one kind of power generation to another, and animate the upside of adopting a carbon-free power grid. The site also needed to promote the initiative amongst teachers and convince them to sign up for free resources and lesson plans.',
            },
        ],
    },
    tech_stack: {
        title: 'Tech Stack',
        text: [
            'We used WordPress as a headless CMS to simplify site management and make it easy to add new materials as the course evolves and grows.',
            "In combination with a Next.js application and static site generation we we're able to create a immensely fast web experience for the visitors. Animations were created using Framer Motion.",
        ],
        tools: [DEVTOOLS.WORDPRESS, DEVTOOLS.NEXTJS, DEVTOOLS.FRAMER_MOTION],
    },
    the_concept: {
        title: 'The big idea',
        components: [
            {
                type: CONTENT_COMPONENT_TYPES.TEXT,
                text: 'We decided that the site should make learning about wind power an immersive experience — the more you scroll, the deeper into the program you go. Since the turbines are mounted on the ocean floor, we wanted to give students a feeling of being at sea to make each lesson more engaging and entertaining. Moving from one stage of the lesson to another should have elements of exploration and adventure, with an element of mystery and a code to crack.',
            },
        ],
    },
    content_frame: {
        title: 'Taking a<br/> deep dive',
    },
    immersion: {
        title: 'How digital and physical lessons create immersion',
        text: [
            'Our approach was to create a digital platform that could extend elements of the physical classroom into the online experience. We used animations to ensure visual interest and engagement and optimised the interface so that interactive smartboards could be embedded on the page.',
        ],
        image: {
            path: 'projects/van-wind-naar-watt/empty-classroom-with-whiteboard.jpg',
            alt: 'Empty classroom with whiteboard',
        },
    },
    solving_through_learning: {
        title: 'Solving a mystery through learning',
        text: [
            "To engage students, we added a mystery to the site narrative that only moving through the site could solve. Two characters, Ciara and Windson, find themselves stuck on an ocean wind turbine. Working together as a class, students try to set them free by unravelling a riddle. They do so by answering questions that demonstrate what they've learned about wind power.",
        ],
        image: {
            path: 'projects/van-wind-naar-watt/turbine-help.png',
            alt: 'Van wind naar watt turbine asking for help',
        },
        textLeft: true,
    },
    always_in_reach: {
        title: 'Always in reach',
        text: [
            'To engage teachers, we made the site a portal where they could register for access to physical course materials or download digital versions directly. The site also allows teachers and students to work on course documents and assignments together.',
        ],
        image: {
            path: 'projects/van-wind-naar-watt/van-wind-naar-watt-website-on-mobile-and-desktop.png',
            alt: 'Van wind naar watt website on mobile and desktop',
        },
    },
    educational_videos: {
        title: 'Educational video’s and hidden clue’s',
        text: [
            "Each digital lesson consist of video’s and images. With the animated video’s the students learn about wind energy. In order to solve the mystery students have to pay good attention to solve the clue's with the hint images!",
        ],
        image: {
            path: 'projects/van-wind-naar-watt/van-wind-naar-watt-board.png',
            alt: 'Van wind naar watt website on latop and a board',
        },
        textLeft: true,
    },
    cracking_code: {
        title: 'Cracking the code',
        text: [
            'The moment is finally here, the students have learned about wind energy and solved all the clues. But have they solved the mystery? Only one way to find out! By cracking the code students will find out if they have found the correct answers in order to save Ciara and Windson!',
        ],
        image: {
            path: 'projects/van-wind-naar-watt/design-like-energy-meter.png',
            alt: 'Crack the code design like a energy meter',
        },
        textLeft: true,
    },
    code_game: {
        title: 'Try it out!',
    },
    grand_prize: {
        title: 'The grand prize!',
        text: [
            'After cracking the code the class will be greeted with the chance to win an amazing prize! They can name a real life windmill park! The class that comes up with the best name will get a real park named after it.',
        ],
        image: {
            path: 'projects/van-wind-naar-watt/certificate.png',
            alt: 'Van wind naar watt certificate',
        },
        textLeft: true,
    },
    reviews: {
        title: 'What schools thought about Van Wind naar Watt',
        reviews: [
            {
                title: 'Elementary School St. Martinus, Venlo',
                text: [
                    'It is a fun and very active way of teaching, where the students have to work together to find the answers.',
                ],
            },
            {
                title: 'KBS De Golfslag, Swifterbant',
                text: [
                    'I used the lesson as a start for our own windmill project. The government is building a windmill park near our village. Students are coming up with creative ideas and solutions on how we can make the windmill park more eco-friendly and how we can make it more appealing for humans, animals and nature',
                ],
            },
            {
                title: 'IKC Het Talent, Amsterdam',
                text: [
                    'The students really liked to get started with the lessons. It is a totally new way of thinking for the kids!',
                ],
            },
        ],
    },
    project_question: {
        title: 'Got a question about this project? <br/> Ask |EMPLOYEE|',
        employee: jamesVanArkel,
    },
    other_projects: {
        title: 'Other <span>projects</span> we worked on',
        projects: [
            {
                path: '/work/blast-galaxy',
                title: 'In a Galaxy not so very far, far away...',
                thumbnail: {
                    background_color: '#0F0C24',
                    image: {
                        path: 'projects/blast-galaxy/blast-galaxy-thumbnail.png',
                        alt: 'Blast Galaxy arcade',
                    },
                },
            },
            {
                title: 'How to support the business owners of Haarlem during a global lockdown?',
                thumbnail: {
                    background_color: '#c8293a',
                    image: {
                        path: 'projects/haarlemse-winkels/haarlemse-winkels-thumbnail.png',
                        alt: 'Haarlemse winkels',
                    },
                },
                live_demo: 'https://haarlemsewinkels.nl',
            },
            {
                title: 'How to shred the tattoo shop stigma with a clients first web experience?',
                thumbnail: {
                    background_color: '#91bfc0',
                    image: {
                        path: 'projects/le-nou-tattou/le-nou-tattou-thumbnail.png',
                        alt: 'Le Nou Tattou - Art in Ink',
                    },
                },
                live_demo: 'https://lenou.nl',
            },
        ],
    },
};
