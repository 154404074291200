import React from 'react';

//Styles
import styles from './Confetti.module.scss';

export const Confetti = ({ className }) => {
    return (
        <svg
            className={`${styles.confetti} ${className ? className : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 1646 945"
        >
            <g transform="">
                <g transform="translate(0 80.39170895529077)">
                    <g transform="translate(694.4708857568869 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{ animationDelay: 0, animationDuration: '2s' }}
                        >
                            <rect
                                x="-5.102465116135251"
                                y="-3.0722490470398194"
                                width="10.204930232270502"
                                height="6.144498094079639"
                                style={{ transform: 'scale(2.6085050633424203)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>
                    <g transform="translate(69.87916869726173 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.5567926860671077s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.7717119728081485"
                                y="-4.090976088005309"
                                width="11.543423945616297"
                                height="8.181952176010618"
                                style={{ transform: 'scale(2.1254763319867562)' }}
                                fill="#4355db"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(746.449592966709 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.0141472301072185s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.8897674948798295"
                                y="-3.944634245818941"
                                width="9.779534989759659"
                                height="7.889268491637882"
                                style={{ transform: 'scale(1.7030186430992469)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1153.765926589371 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.6678366202372663s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.7976121328315475"
                                y="-4.181706668173179"
                                width="11.595224265663095"
                                height="8.363413336346358"
                                style={{ transform: 'scale(2.821925434606282)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(629.688069827441 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.944504123281476s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.611839967473196"
                                y="-4.223222818624579"
                                width="11.223679934946391"
                                height="8.446445637249157"
                                style={{ transform: 'scale(2.766566534176368)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1262.7297385367392 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.33225935496281966s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.7416583195984074"
                                y="-5.078598697904248"
                                width="11.483316639196815"
                                height="10.157197395808495"
                                style={{ transform: 'scale(1.7973064171594673)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(670.5722958911666 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.07355559658774924s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.454451111697701"
                                y="-3.6677793008838613"
                                width="8.908902223395401"
                                height="7.335558601767723"
                                style={{ transform: 'scale(2.952265736664474)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(924.609260591999 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.0150127418259345s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.6012808404725085"
                                y="-3.1210356709412967"
                                width="9.202561680945017"
                                height="6.2420713418825935"
                                style={{ transform: 'scale(2.0841223673936593)' }}
                                fill="#34bbe6"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1513.7504733400676 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-3.302340844403096s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-3.0002776421018025"
                                y="-4.63180204058395"
                                width="6.000555284203605"
                                height="9.2636040811679"
                                style={{ transform: 'scale(3.3493774901196742)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(195.6522859067529 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.4950604547755306s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.196027200272301"
                                y="-3.64519906837081"
                                width="8.392054400544602"
                                height="7.29039813674162"
                                style={{ transform: 'scale(2.5303833064489742)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1622.6026263585582 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.47496352596742586s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.870672117224911"
                                y="-3.697974659995821"
                                width="9.741344234449821"
                                height="7.395949319991642"
                                style={{ transform: 'scale(2.630392123591889)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(477.022302620281 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.718026514574908s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.6043571350803933"
                                y="-3.784520388543939"
                                width="7.208714270160787"
                                height="7.569040777087878"
                                style={{ transform: 'scale(2.6464237904811716)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1261.7692159696767 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-2.9193544615601628s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-4.1274069236603115"
                                y="-5.58351987136915"
                                width="8.254813847320623"
                                height="11.1670397427383"
                                style={{ transform: 'scale(2.3328115556814955)' }}
                                fill="#f7d038"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1276.7155819644051 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.8969118626983548s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.240782524810162"
                                y="-3.5004687286802554"
                                width="10.481565049620324"
                                height="7.000937457360511"
                                style={{ transform: 'scale(2.2114593522385424)' }}
                                fill="#4355db"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1121.657033176911 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.09556044070270087s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.3893924647958205"
                                y="-3.3106202335778327"
                                width="10.778784929591641"
                                height="6.6212404671556655"
                                style={{ transform: 'scale(3.1909124935453375)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(832.502301483414 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.1006106656575785s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-4.9575114103559415"
                                y="-3.2154654979718185"
                                width="9.915022820711883"
                                height="6.430930995943637"
                                style={{ transform: 'scale(2.623256022743929)' }}
                                fill="#34bbe6"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(508.0166554790833 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.5253698741403956s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.0135483792552975"
                                y="-3.3127731270475196"
                                width="10.027096758510595"
                                height="6.625546254095039"
                                style={{ transform: 'scale(2.870133904750505)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1495.633803864516 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.49538729389974057s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.10556251684395"
                                y="-3.186662571310257"
                                width="10.2111250336879"
                                height="6.373325142620514"
                                style={{ transform: 'scale(3.187891815139823)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(215.88137113696908 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.9920035039841384s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.353534467413248"
                                y="-4.858234308082412"
                                width="6.707068934826496"
                                height="9.716468616164825"
                                style={{ transform: 'scale(1.7783316700759464)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1161.6602962911397 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.5339726185940057s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.481633014707846"
                                y="-3.826078842995499"
                                width="8.963266029415692"
                                height="7.652157685990998"
                                style={{ transform: 'scale(3.2103082863334045)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(97.33336982238748 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-3.5406225875079365s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.01861690556115"
                                y="-3.886117008267933"
                                width="10.0372338111223"
                                height="7.772234016535866"
                                style={{ transform: 'scale(1.6610813022230466)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(867.6718595891211 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.3306711248932959s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.7201937602622284"
                                y="-3.833459782523397"
                                width="7.440387520524457"
                                height="7.666919565046794"
                                style={{ transform: 'scale(3.168134614762408)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(927.2079820720759 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.4051466519656932s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.1704227422682245"
                                y="-5.199009660617763"
                                width="6.340845484536449"
                                height="10.398019321235527"
                                style={{ transform: 'scale(3.4903276673857775)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1130.3671068483598 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.8562221068710771s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.549044898795947"
                                y="-4.808605066863755"
                                width="9.098089797591895"
                                height="9.61721013372751"
                                style={{ transform: 'scale(1.5935812196713264)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(364.46552652536633 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.10626226540386607s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.9959197550721184"
                                y="-4.633673712133323"
                                width="7.991839510144237"
                                height="9.267347424266646"
                                style={{ transform: 'scale(2.6480563407821203)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>
                </g>
                <g transform="translate(0 -864.6082910447092)">
                    <g transform="translate(694.4708857568869 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{ animationDelay: 0, animationDuration: '2s' }}
                        >
                            <rect
                                x="-5.102465116135251"
                                y="-3.0722490470398194"
                                width="10.204930232270502"
                                height="6.144498094079639"
                                style={{ transform: 'scale(2.6085050633424203)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(69.87916869726173 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.5567926860671077s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.7717119728081485"
                                y="-4.090976088005309"
                                width="11.543423945616297"
                                height="8.181952176010618"
                                style={{ transform: 'scale(2.1254763319867562)' }}
                                fill="#4355db"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(746.449592966709 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.0141472301072185s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.8897674948798295"
                                y="-3.944634245818941"
                                width="9.779534989759659"
                                height="7.889268491637882"
                                style={{ transform: 'scale(1.7030186430992469)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1153.765926589371 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.6678366202372663s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.7976121328315475"
                                y="-4.181706668173179"
                                width="11.595224265663095"
                                height="8.363413336346358"
                                style={{ transform: 'scale(2.821925434606282)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(629.688069827441 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.944504123281476s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.611839967473196"
                                y="-4.223222818624579"
                                width="11.223679934946391"
                                height="8.446445637249157"
                                style={{ transform: 'scale(2.766566534176368)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1262.7297385367392 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.33225935496281966s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.7416583195984074"
                                y="-5.078598697904248"
                                width="11.483316639196815"
                                height="10.157197395808495"
                                style={{ transform: 'scale(1.7973064171594673)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(670.5722958911666 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.07355559658774924s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.454451111697701"
                                y="-3.6677793008838613"
                                width="8.908902223395401"
                                height="7.335558601767723"
                                style={{ transform: 'scale(2.952265736664474)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(924.609260591999 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.0150127418259345s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.6012808404725085"
                                y="-3.1210356709412967"
                                width="9.202561680945017"
                                height="6.2420713418825935"
                                style={{ transform: 'scale(2.0841223673936593)' }}
                                fill="#34bbe6"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1513.7504733400676 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-3.302340844403096s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-3.0002776421018025"
                                y="-4.63180204058395"
                                width="6.000555284203605"
                                height="9.2636040811679"
                                style={{ transform: 'scale(3.3493774901196742)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(195.6522859067529 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.4950604547755306s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.196027200272301"
                                y="-3.64519906837081"
                                width="8.392054400544602"
                                height="7.29039813674162"
                                style={{ transform: 'scale(2.5303833064489742)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1622.6026263585582 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.47496352596742586s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.870672117224911"
                                y="-3.697974659995821"
                                width="9.741344234449821"
                                height="7.395949319991642"
                                style={{ transform: 'scale(2.630392123591889)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(477.022302620281 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.718026514574908s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.6043571350803933"
                                y="-3.784520388543939"
                                width="7.208714270160787"
                                height="7.569040777087878"
                                style={{ transform: 'scale(2.6464237904811716)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1261.7692159696767 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-2.9193544615601628s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-4.1274069236603115"
                                y="-5.58351987136915"
                                width="8.254813847320623"
                                height="11.1670397427383"
                                style={{ transform: 'scale(2.3328115556814955)' }}
                                fill="#f7d038"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1276.7155819644051 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.8969118626983548s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.240782524810162"
                                y="-3.5004687286802554"
                                width="10.481565049620324"
                                height="7.000937457360511"
                                style={{ transform: 'scale(2.2114593522385424)' }}
                                fill="#4355db"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1121.657033176911 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.09556044070270087s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-5.3893924647958205"
                                y="-3.3106202335778327"
                                width="10.778784929591641"
                                height="6.6212404671556655"
                                style={{ transform: 'scale(3.1909124935453375)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(832.502301483414 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.1006106656575785s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-4.9575114103559415"
                                y="-3.2154654979718185"
                                width="9.915022820711883"
                                height="6.430930995943637"
                                style={{ transform: 'scale(2.623256022743929)' }}
                                fill="#34bbe6"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(508.0166554790833 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.5253698741403956s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.0135483792552975"
                                y="-3.3127731270475196"
                                width="10.027096758510595"
                                height="6.625546254095039"
                                style={{ transform: 'scale(2.870133904750505)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1495.633803864516 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.49538729389974057s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.10556251684395"
                                y="-3.186662571310257"
                                width="10.2111250336879"
                                height="6.373325142620514"
                                style={{ transform: 'scale(3.187891815139823)' }}
                                fill="#e6261f"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(215.88137113696908 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.9920035039841384s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.353534467413248"
                                y="-4.858234308082412"
                                width="6.707068934826496"
                                height="9.716468616164825"
                                style={{ transform: 'scale(1.7783316700759464)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1161.6602962911397 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.5339726185940057s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.481633014707846"
                                y="-3.826078842995499"
                                width="8.963266029415692"
                                height="7.652157685990998"
                                style={{ transform: 'scale(3.2103082863334045)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(97.33336982238748 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-3.5406225875079365s',
                                animationDuration: '2s',
                            }}
                        >
                            <rect
                                x="-5.01861690556115"
                                y="-3.886117008267933"
                                width="10.0372338111223"
                                height="7.772234016535866"
                                style={{ transform: 'scale(1.6610813022230466)' }}
                                fill="#eb7532"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(867.6718595891211 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.3306711248932959s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.7201937602622284"
                                y="-3.833459782523397"
                                width="7.440387520524457"
                                height="7.666919565046794"
                                style={{ transform: 'scale(3.168134614762408)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(927.2079820720759 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.4051466519656932s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.1704227422682245"
                                y="-5.199009660617763"
                                width="6.340845484536449"
                                height="10.398019321235527"
                                style={{ transform: 'scale(3.4903276673857775)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(1130.3671068483598 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-1.8562221068710771s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-4.549044898795947"
                                y="-4.808605066863755"
                                width="9.098089797591895"
                                height="9.61721013372751"
                                style={{ transform: 'scale(1.5935812196713264)' }}
                                fill="#d23be7"
                            ></rect>
                        </g>
                    </g>{' '}
                    <g transform="translate(364.46552652536633 0)">
                        <g
                            className={`${styles.confetti} ${styles.confettiFall}`}
                            style={{
                                animationDelay: '-0.10626226540386607s',
                                animationDuration: '4s',
                            }}
                        >
                            <rect
                                x="-3.9959197550721184"
                                y="-4.633673712133323"
                                width="7.991839510144237"
                                height="9.267347424266646"
                                style={{ transform: 'scale(2.6480563407821203)' }}
                                fill="#a3e048"
                            ></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
