import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import styles from './ContentFrame.module.scss';

//Components
import Img from 'gatsby-image';
import { Container } from '@components/Utilities/Container/Container';

export const ContentFrame = props => {
    const { children, title } = props;

    const imagesData = useStaticQuery(graphql`
        query {
            sky: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-content-frame-sky.jpg"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            wave: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-content-frame-wave.png"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            wavefront: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-content-frame-wave-front.png"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            bottle: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-content-frame-bottle.png"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            gradient: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-content-frame-gradient.png"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    if (!children) return null;

    return (
        <div className={styles.contentFrame}>
            <div className={styles.contentFrame__imagesWrapper}>
                {imagesData.sky && (
                    <Img
                        className={styles.contentFrame__sky}
                        fluid={imagesData.sky.childImageSharp.fluid}
                        alt="Van wind naar watt sky"
                    />
                )}

                {title && (
                    <Container className={styles.contentFrame__titleWrapper}>
                        <h2
                            className={styles.contentFrame__title}
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></h2>
                    </Container>
                )}

                {imagesData.wave && (
                    <Img
                        style={{ position: 'absolute' }}
                        className={styles.contentFrame__wave}
                        fluid={imagesData.wave.childImageSharp.fluid}
                        alt="Van wind naar watt sea wave"
                    />
                )}

                {imagesData.wavefront && (
                    <Img
                        style={{ position: 'absolute' }}
                        className={styles.contentFrame__waveFront}
                        fluid={imagesData.wavefront.childImageSharp.fluid}
                        alt="Van wind naar watt sea wave front"
                    />
                )}

                {imagesData.bottle && (
                    <Img
                        style={{ position: 'absolute' }}
                        className={styles.contentFrame__bottle}
                        fluid={imagesData.bottle.childImageSharp.fluid}
                        alt="Van wind naar watt message in a bottle"
                    />
                )}
            </div>

            <div className={styles.contentFrame__content}>
                {imagesData.gradient && (
                    <Img
                        style={{ position: 'absolute' }}
                        className={styles.contentFrame__gradient}
                        fluid={imagesData.gradient.childImageSharp.fluid}
                        alt="Blue gradient"
                    />
                )}

                {children}
            </div>
        </div>
    );
};
