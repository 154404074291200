import React, { useState } from 'react';

//Styles
import styles from './CodeGame.module.scss';

export const Input = props => {
    const { success, failed, updateCode, value, index } = props;
    const [touched, setTouched] = useState(false);

    return (
        <input
            className={`${styles.codeGame__input} ${success ? styles.codeGame__inputSuccess : ''} ${
                failed ? styles.codeGame__inputFailed : ''
            } ${touched ? styles.codeGame__inputTouched : ''}`}
            type="number"
            required
            minLength="1"
            maxLength="1"
            max="9"
            min="0"
            placeholder="0"
            onBlur={e => setTouched(e.target.value ? true : false)}
            onChange={e => {
                const initialValue = e.target.value.replace(/[^0-9]/g, '');
                const allNumbers = initialValue.split('');
                const lastNumber = allNumbers[allNumbers.length - 1];

                const value = !lastNumber ? 0 : lastNumber;
                updateCode(index, value);

                setTouched(e.target.value ? true : false);

                if (
                    e.target.nextElementSibling &&
                    e.target.nextElementSibling.tagName === 'INPUT' &&
                    e.target.nextElementSibling.type === 'number'
                ) {
                    e.target.nextElementSibling.focus();
                }
            }}
            onKeyDown={e => {
                const key = e.keyCode || e.charCode;
                // If key is not a number
                if (key < 48 || key > 57) {
                    e.preventDefault();
                }

                // If key is backspace or del
                if (key === 8 || key === 46) {
                    e.preventDefault();
                    // Reset value to 0
                    updateCode(index, 0);
                }
            }}
            value={value}
        />
    );
};
