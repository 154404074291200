import React from 'react';

//Styles
import styles from './ContentImage.module.scss';

//Components
import { Image } from './Image';
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const ContentImage = props => {
    const { image, title, text = [], textLeft, alignCenter } = props;

    return (
        <section
            className={`${styles.contentImage} ${textLeft ? styles.contentImageTextLeft : ''} ${
                alignCenter ? styles.contentImageAlignCenter : ''
            }`}
        >
            <Container className={styles.contentImage__container}>
                <FadeInWhenVisible
                    threshold={0.15}
                    direction={textLeft ? 'right' : 'left'}
                    className={styles.contentImage__imageWrapper}
                >
                    <Image
                        className={styles.contentImage__image}
                        path={image.path}
                        alt={image.alt}
                    />
                </FadeInWhenVisible>

                <div className={styles.contentImage__content}>
                    {title && (
                        <FadeInWhenVisible>
                            <h2 className="h4">{title}</h2>
                        </FadeInWhenVisible>
                    )}

                    {text.map((p, i) => {
                        return (
                            <FadeInWhenVisible key={i}>
                                <p>{p}</p>
                            </FadeInWhenVisible>
                        );
                    })}
                </div>
            </Container>
        </section>
    );
};
