import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import styles from './CodeGame.module.scss';

//Components
import Img from 'gatsby-image';
import { Container } from '@components/Utilities/Container/Container';
import { Input } from './Input';
import { Confetti } from '@components/ProjectsComponents/VanWindNaarWatt/Confetti/Confetti.jsx';

//Images
import CodeArrowImage from '@images/projects/van-wind-naar-watt/van-wind-naar-watt-code-arrow.svg';
import CodeNoArrowImage from '@images/projects/van-wind-naar-watt/van-wind-naar-watt-code-no-arrow.svg';

export const CodeGame = props => {
    const { title } = props;
    const [fullCode, setFullCode] = useState(new Array(9).fill(''));
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const CODE = '159259374';
    const ref = useRef();

    const imagesData = useStaticQuery(graphql`
        query {
            background: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-code-background.jpg"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 1280, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            blades: file(
                relativePath: {
                    eq: "projects/van-wind-naar-watt/van-wind-naar-watt-code-blades.png"
                }
            ) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    useEffect(() => {
        if (success) {
            ref.current.scrollIntoView({ block: 'center' });
        }
    }, [success]);

    const validateCode = () => {
        if (fullCode.join('') === CODE) {
            setSuccess(true);
            setFailed(false);

            setTimeout(() => {
                setSuccess(false);
                setFailed(false);
            }, 25000);
        } else {
            setSuccess(false);
            setFailed(true);
        }
    };

    const updateCode = (index, value) => {
        const fullCodeCopy = [...fullCode];
        fullCodeCopy[index] = value;
        setFullCode(fullCodeCopy);
    };

    return (
        <section className={`${styles.codeGame} ${success ? styles.codeGameSuccess : ''}`}>
            <Container>{title && <h2 className="h4">{title}</h2>}</Container>

            <Container>
                <div className={styles.codeGame__wrapper}>
                    <div ref={ref} className={styles.codeGame__imagesWrapper}>
                        {imagesData.background && (
                            <Img
                                className={styles.codeGame__background}
                                fluid={imagesData.background.childImageSharp.fluid}
                            />
                        )}

                        {imagesData.blades && (
                            <Img
                                className={styles.codeGame__blades}
                                style={{ position: 'absolute' }}
                                fluid={imagesData.blades.childImageSharp.fluid}
                            />
                        )}

                        {success && <Confetti className={styles.codeGame__confetti} />}
                    </div>

                    <div className={styles.codeGame__inputWrapper}>
                        <div className={styles.codeGame__inputGroup}>
                            {fullCode.map((input, index) => (
                                <Input
                                    key={index}
                                    index={index}
                                    success={success}
                                    failed={failed}
                                    updateCode={updateCode}
                                    value={fullCode[index]}
                                />
                            ))}
                        </div>

                        <CodeNoArrowImage className={styles.codeGame__codeNoArrow} />

                        <button
                            className={styles.codeGame__button}
                            onClick={validateCode}
                            type="button"
                        >
                            Crack the code!
                        </button>
                    </div>
                </div>

                <CodeArrowImage className={styles.codeGame__codeArrow} />
            </Container>
        </section>
    );
};
