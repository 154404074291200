export const jamesVanArkel = {
    avatar: {
        path: 'employees/jamesvanarkel/james-avatar.png',
        alt: 'James van Arkel avatar',
    },
    first_name: 'James',
    last_name: 'van Arkel',
    role: 'User experience design',
    linkedin: 'https://www.linkedin.com/in/jamesvanarkel/',
    mail: 'james@the-pack.nl',
    phone_number: '0629200442',
};
