import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Components
import Img from 'gatsby-image/withIEPolyfill';

export const Image = props => {
    const { path, alt = '', className } = props;

    const data = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 600, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    `);

    const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(path);
    });

    if (!image) return null;

    const extension = path.match(/[^\\]*\.(\w+)$/)[1];
    if (extension === 'svg' || extension === 'gif') {
        return <img loading="lazy" className={image.node.publicURL} src={path} alt={alt} />;
    }

    return (
        <Img
            style={{
                display: 'block',
                width: '100%',
                maxHeight: '50vh',
            }}
            objectFit="contain"
            objectPosition="center top"
            className={className}
            fluid={image.node.childImageSharp.fluid}
            alt={alt}
        />
    );
};
