import React from 'react';

//Data
import { vanWindNaarWatt as data } from '@data/en/projects/items/van-wind-naar-watt';

//Styles
import styles from '@styles/work/VanWindNaarWatt.module.scss';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { ProjectHero } from '@components/ProjectsComponents/ProjectHero/ProjectHero';
import { Content } from '@components/Content/Content';
import { ContentFrame } from '@components/ProjectsComponents/VanWindNaarWatt/ContentFrame/ContentFrame';
import { ContentImage } from '@components/ProjectsComponents/VanWindNaarWatt/ContentImage/ContentImage';
import { CodeGame } from '@components/ProjectsComponents/VanWindNaarWatt/CodeGame/CodeGame';
import { Reviews } from '@components/ProjectsComponents/VanWindNaarWatt/Reviews/Reviews';
import { ProjectsList } from '@components/ProjectsList/ProjectsList';
import { TechStack } from '@components/ProjectsComponents/TechStack/TechStack';
import { ProjectQuestion } from '@components/ProjectsComponents/ProjectQuestion/ProjectQuestion';
import { Footer } from '@components/Footer/Footer';

//Images
import SEOImage from '@images/openGraph/project-van-wind-naar-watt.jpg';

const VanWindNaarWatt = () => {
    const {
        seo,
        path,
        title,
        hero,
        thumbnail,
        services,
        client_details,
        the_client,
        the_project,
        tech_stack,
        the_concept,
        content_frame,
        immersion,
        solving_through_learning,
        always_in_reach,
        educational_videos,
        cracking_code,
        code_game,
        grand_prize,
        reviews,
        project_question,
        other_projects,
    } = data;

    return (
        <>
            <SEO {...seo} path={path} image={SEOImage} />

            <Header white />

            <div className={styles.vanWindNaarWatt}>
                <ProjectHero
                    title={title}
                    backgroundColor={hero.background_color}
                    services={services}
                    clientDetails={client_details}
                    image={thumbnail.image}
                />

                <Content fullHeight title={the_client.title} components={the_client.components} />

                <Content
                    fullHeight
                    black
                    title={the_project.title}
                    components={the_project.components}
                />

                <TechStack
                    title={tech_stack.title}
                    text={tech_stack.text}
                    tools={tech_stack.tools}
                />

                <ContentFrame title={content_frame.title}>
                    <Content
                        transparent
                        title={the_concept.title}
                        components={the_concept.components}
                    />

                    <ContentImage {...immersion} />

                    <ContentImage {...solving_through_learning} />

                    <ContentImage {...always_in_reach} />

                    <ContentImage {...educational_videos} />

                    <ContentImage {...cracking_code} />

                    <CodeGame title={code_game.title} />

                    <ContentImage {...grand_prize} />

                    <Reviews title={reviews.title} reviews={reviews.reviews} />
                </ContentFrame>
            </div>

            <ProjectQuestion title={project_question.title} employee={project_question.employee} />

            <ProjectsList small title={other_projects.title} projects={other_projects.projects} />

            <Footer />
        </>
    );
};

export default VanWindNaarWatt;
