import React, { useEffect } from 'react';
import Tilt from 'react-parallax-tilt';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//Styles
import styles from './Reviews.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const Reviews = props => {
    const { title, reviews = [] } = props;
    const shouldReduceMotion = useReducedMotion();
    const { ref, inView } = useInView({ triggerOnce: true });
    const controls = useAnimation({ threshold: 0.5 });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [inView, controls]);

    if (!reviews.length) return null;

    const renderedReviews = reviews.map((review, index) => {
        const { title, text = [] } = review;

        if (!text) return null;

        const reviewVariants = !shouldReduceMotion
            ? {
                  hidden: {
                      opacity: 0,
                      y: 25,
                  },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
              }
            : {};

        return (
            <Tilt
                tiltReverse={true}
                tiltMaxAngleX={10}
                tiltMaxAngleY={10}
                gyroscope={true}
                key={index}
                className={styles.reviews__item}
            >
                <motion.div
                    variants={reviewVariants}
                    custom={index}
                    className={styles.reviews__reviewInner}
                >
                    <h3 className={`${styles.reviews__reviewTitle} h4`}>{title}</h3>

                    {text.map((p, index) => {
                        return (
                            <p key={index}>
                                {/* Quotationmark if its the first paragraph */}
                                {index === 0 ? (
                                    <span className={styles.quoteSlider__quotationMark}>
                                        &#8220;
                                    </span>
                                ) : null}

                                {p}

                                {/* Quotationmark if its the last paragraph */}
                                {index === text.length - 1 ? (
                                    <span className={styles.quoteSlider__quotationMark}>
                                        &#8221;
                                    </span>
                                ) : null}
                            </p>
                        );
                    })}
                </motion.div>
            </Tilt>
        );
    });

    const containerVariants = {
        hidden: {},
        visible: { transition: { staggerChildren: 0.5 } },
    };

    return (
        <motion.section
            variants={containerVariants}
            initial="hidden"
            animate={controls}
            ref={ref}
            className={styles.reviews}
        >
            {title && (
                <Container>
                    <FadeInWhenVisible>
                        <h3 className={`${styles.reviews__title} h4`}>{title}</h3>
                    </FadeInWhenVisible>
                </Container>
            )}

            <Container>
                <div className={styles.reviews__row}>{renderedReviews}</div>
            </Container>
        </motion.section>
    );
};
